import { CircularProgress } from "@material-ui/core"
import Grid from "@mui/material/Grid"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory, useRouteMatch } from "react-router"
import {
  get_questions_by_rmid,
  get_the_status,
  NOT_STARTED,
  update_the_questions,
  VALIDATED,
} from "../../../services/roadmap"
import "./chosenimprove.scss"
import Button from "@mui/material/Button"
import WarningIcon from "@mui/icons-material/Warning"
import CustomNextBtn from "../../utilities/Buttons/NextButton"
import { set_log_time } from "../../../redux/actions/timeAction"
import { v4 } from "uuid"
import { getContent } from "../../../services/Content"
import { Checkbox } from "@mui/material"
import { orange } from "@mui/material/colors"
import { set_roadmap_id } from "../../../redux/actions/roadmap"
import Alert from "../../utilities/Modals/alert/Alert"
import ExitBtn from "../../utilities/Buttons/ExitBtn"
import FinishProfileInfo from "../../utilities/Modals/finishProfileInfo/FinishProfileInfo"
import FinishModal from "../../utilities/Modals/finishModal"
import FinishInfoRoadmapModal from "../../utilities/Modals/FinishInfoRoadmapModal/FinishInfoRoadmapModal"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import pdfFile from "../../../images/pdf_file.png"

import { getRoadmapFile } from "../../../services/files"
import { set_cur_roadmap_tablePage } from "../../../redux/actions/commonActions"

const mxNumberOfQuestionsPerPage = 5

const ChoosenImprovment = ({
  roadMapId,
  userId,
  checkTheTimeGab,
  handleLogOut,
  dispatch,
  common,
}) => {
  const [questionOfRaodMap, setquestionOfRaodMap] = useState([])
  const [selectedBoxes, setselectedBoxes] = useState([])

  const [loading, setloading] = useState(false)
  const [alertStatus, setalertStatus] = useState(false)
  const [finishModalStatus, setfinishModalStatus] = useState(false)
  const [pdfLoading, setpdfLoading] = useState(false)
  const [alertNoPdfStatus, setalertNoPdfStatus] = useState(false)

  const [finishRoadmapInfoModalStatus, setfinishRoadmapInfoModalStatus] =
    useState(false)

  const [title, settitle] = useState("")
  const [alertText, setalertText] = useState("")
  const [alertNoPdfText, setalertNoPdfText] = useState(false)

  const [nameTableText, setnameTableText] = useState("")
  const [activeLevelText, setactiveLevelText] = useState("")
  const [tableNumberText, settableNumberText] = useState("")
  const [objectiveLevelText, setobjectiveLevelText] = useState("")
  const [detailsText, setdetailsText] = useState("")
  const [completeText, setcompleteText] = useState("")
  const [goToQuestionText, setgoToQuestionText] = useState("")

  const [pdfFileText, setpdfFileText] = useState("")

  const history = useHistory()
  const { location, push } = history
  const { newRoadMapId } = setTheStateFromRoute()
  const [numberOfNewSelectedBoxs, setnumberOfNewSelectedBoxs] = useState(0)
  let { path, url } = useRouteMatch()
  const [showPdf, setshowPdf] = useState(1)
  const [page, setpage] = useState(common.roadMapTablePage)
  const [maxPage, setmaxPage] = useState(1)

  useEffect(() => {
    if (!newRoadMapId) {
      push("/home/questions_list/chart/createroadmap")
      return
    }
    handleTheautologout()
    getAllPageContent()
  }, [])

  useEffect(() => {
    return async () => {
      if (history.action === "POP") {
        const status = await getTheRMStatus()
        if (status === NOT_STARTED) {
          push(path)
        }
      }
    }
  }, [history.action])

  useEffect(() => {
    setloading(true)
    getTheQuestions()
      .then((res) => {
        setmaxPage(
          Math.floor(res.data.length / mxNumberOfQuestionsPerPage) +
            (res.data.length % mxNumberOfQuestionsPerPage > 0)
        )
        setquestionOfRaodMap([...res.data])
        setloading(false)
      })
      .catch((e) => console.log(e.message))
  }, [])

  function getAllPageContent() {
    getContent("see_improvement_page").then((data) => {
      const arr = data.content
      settitle(arr[0].value)
      setalertText(arr[1].value)
      setnameTableText(arr[2].value)
      setactiveLevelText(arr[3].value)
      settableNumberText(arr[4].value)
      setobjectiveLevelText(arr[5].value)
      setcompleteText(arr[6].value)
      setgoToQuestionText(arr[7].value)
      setpdfFileText(arr[8].value)
      setdetailsText(arr[9].value)
      setalertNoPdfText(arr[10].value)
    })
  }

  function handleTheautologout() {
    if (checkTheTimeGab()) {
      handleLogOut()
    } else {
      dispatch(set_log_time(new Date().getTime()))
    }
  }

  function getNumberOfValidatedCheckbox() {
    let num = 0
    for (let i = 0; i < questionOfRaodMap.length; i++) {
      if (selectedBoxes[i]) ++num
    }
    return num
  }

  function handleClickOnChekBox(idx) {
    if (questionOfRaodMap[idx].status === VALIDATED) {
      return
    }
    if (selectedBoxes[idx])
      setnumberOfNewSelectedBoxs(numberOfNewSelectedBoxs - 1)
    else setnumberOfNewSelectedBoxs(numberOfNewSelectedBoxs + 1)
    let val = selectedBoxes[idx]
    selectedBoxes[idx] = !val
    setselectedBoxes([...selectedBoxes])
  }

  function setTheStateFromRoute() {
    if (location.state) {
      return {
        newRoadMapId: location.state.newRoadMapId,
      }
    }
    return {
      newRoadMapId: null,
    }
  }

  async function getTheQuestions() {
    const resopnse = await get_questions_by_rmid(newRoadMapId)
    const res = await resopnse.json()
    setshowPdf(res.show_pdf)
    const arr = [...res.data].map((item) => {
      if (item.status === VALIDATED) {
        return true
      }
      return false
    })
    setselectedBoxes(arr)
    return res
  }

  function handleSolveThisQuestio(question, rmStatus) {
    dispatch(set_roadmap_id(location.state.newRoadMapId))
    push({
      pathname: `/home/questions_list/chart2/RoadMapQuestions/question`,
      state: {
        question,
        newRoadMapId,
        rmStatus,
      },
    })
  }

  async function handleSomeOfCheckboxSelected() {
    return await updateTheQuetions().then(() => {
      return push("/home/questions_list/chart")
    })
  }
  async function getTheRMStatus() {
    const response = await get_the_status(newRoadMapId)
    const res = await response.json()
    return res.status
  }

  async function handleTheFile() {
    if (showPdf === 0) {
      return setalertNoPdfStatus(true)
    }
    setpdfLoading(true)
    await getRoadmapFile(newRoadMapId, userId.user_id_company)
    setpdfLoading(false)
  }

  async function handleValider() {
    let numberOfValidatedCheckbox = getNumberOfValidatedCheckbox()

    if (numberOfValidatedCheckbox === 0) {
      return setalertStatus(true)
    }

    if (numberOfValidatedCheckbox === questionOfRaodMap.length) {
      return setfinishRoadmapInfoModalStatus(true)
    }

    return setfinishModalStatus(true)
    // await updateTheQuetions();
  }

  async function updateTheQuetions() {
    const idsOfSelectedBoxes = getTheIdsOfQuestions()
    await update_the_questions(newRoadMapId, idsOfSelectedBoxes)
  }

  function getTheIdsOfQuestions() {
    let obj = {}
    selectedBoxes.forEach((item, idx) => {
      let id = questionOfRaodMap[idx].id
      obj[`${id}`] = item ? 1 : 0
    })
    return obj
  }

  function handleAbandon() {
    push({
      pathname: `/home/questions_list/chart2/RoadMapQuestions/abandon-roadmap`,
      state: {
        newRoadMapId,
      },
    })
  }
  function handleNextPage() {
    if (page >= maxPage) return
    dispatch(set_cur_roadmap_tablePage(page + 1))
    setpage(page + 1)
  }
  function handlePreviousPage() {
    if (page <= 1) return
    dispatch(set_cur_roadmap_tablePage(page - 1))
    setpage(page - 1)
  }

  function handleClosePDFAlert() {
    setalertNoPdfStatus(false)
  }

  if (loading) {
    return (
      <CircularProgress
        size={50}
        thickness={4}
        style={{ color: "#ff6500", margin: "auto" }}
      />
    )
  } else {
    return (
      <div className="improvement-container">
        <FinishModal
          routePage="/home/questions_list/chart"
          type="after_checking_some_of_checkboxe"
          finishModalStatus={finishModalStatus}
          handleCloseFinishModal={() => setfinishModalStatus(false)}
          validateRoadmap={true}
          handleComplete={handleSomeOfCheckboxSelected}
        />

        {/* <Alert
          title={alertNoPdfText}
          handleCloseAlert={}
          alertStatus={alertNoPdfStatus}
        /> */}
        <Alert
          title={alertNoPdfText}
          handleCloseAlert={handleClosePDFAlert}
          alertStatus={alertNoPdfStatus}
        />

        <FinishInfoRoadmapModal
          routePage="/home/finish_roadmap"
          type="after_checking_some_of_checkboxe"
          finishModalStatus={finishRoadmapInfoModalStatus}
          handleCloseFinishModal={() => setfinishRoadmapInfoModalStatus(false)}
          validateRoadmap={false}
          handleComplete={getTheIdsOfQuestions}
          newRoadMapId={newRoadMapId}
        />

        <FinishProfileInfo
          handleCloseFinishModal={() => setalertStatus(false)}
          finishModalStatus={alertStatus}
          text={alertText}
          shouldCloseOnClick={false}
          isItForValidateRoadmap={true}
        />
        <ExitBtn routePage="/home/questions_list/chart/createroadmap" />
        <div className="title-container">
          <p dangerouslySetInnerHTML={{ __html: title }}></p>
        </div>
        <Grid
          container
          spacing={0.5}
          className="grid-container-header"
          sx={{ width: "90%", margin: "auto" }}
        >
          <Grid container spacing={1.5} className="grid-title">
            <Grid key={v4()} item xs={1} textAlign={"center"}>
              <span
                dangerouslySetInnerHTML={{ __html: tableNumberText }}
              ></span>
            </Grid>
            <Grid key={v4()} item xs={3.5}>
              <span dangerouslySetInnerHTML={{ __html: nameTableText }}></span>
            </Grid>
            <Grid key={v4()} textAlign={"center"} item xs={1.5}>
              <span
                dangerouslySetInnerHTML={{ __html: activeLevelText }}
              ></span>
            </Grid>
            <Grid key={v4()} textAlign={"center"} item xs={1.5}>
              <span
                dangerouslySetInnerHTML={{ __html: objectiveLevelText }}
              ></span>
            </Grid>
            <Grid key={v4()} textAlign={"center"} item xs={1.5}>
              <span dangerouslySetInnerHTML={{ __html: completeText }}></span>
            </Grid>
            <Grid key={v4()} textAlign={"center"} item xs={3}></Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0.5}
          className="grid-container"
          sx={{
            width: "90%",
            margin: "auto",
            "&::-webkit-scrollbar": {
              width: 10,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "gray",
              borderRadius: 2,
            },
          }}
        >
          {questionOfRaodMap.map((item, i) => {
            if (
              i >= mxNumberOfQuestionsPerPage * (page - 1) &&
              i < mxNumberOfQuestionsPerPage * page
            )
              return (
                <div className="grid-row">
                  <Grid
                    key={v4()}
                    className={`grid_cell number ${
                      item.status === "validated"
                        ? "validated"
                        : "not-validated"
                    }`}
                    item
                    xs={1}
                  >
                    <span>{i + 1}</span>
                  </Grid>
                  <Grid key={v4()} item xs={3.5} className="question-text">
                    <span
                      className={`question-name ${
                        item.status === "validated"
                          ? "validated"
                          : "not-validated"
                      } `}
                    >
                      {item.question}
                      {item.subtitle && <span>{`(${item.subtitle})`}</span>}
                    </span>
                  </Grid>
                  <Grid key={v4()} className="grid_cell" item xs={1.5}>
                    {item.supervision_choice && (
                      <p
                        className={`${
                          item.status === "validated"
                            ? "validated"
                            : "not-validated"
                        }`}
                      >
                        {item.supervision_previous_choice
                          ? item.supervision_previous_choice.points
                          : item.supervision_choice.points}
                      </p>
                    )}
                  </Grid>
                  <Grid key={v4()} className="grid_cell" item xs={1.5}>
                    {Boolean(item.id_choice) ? (
                      <p
                        className={`${
                          item.status === "validated"
                            ? "validated"
                            : "not-validated"
                        }`}
                        dangerouslySetInnerHTML={{
                          __html: item.roadmap_choice.points,
                        }}
                      ></p>
                    ) : (
                      <WarningIcon color="warning" />
                    )}
                  </Grid>
                  <Grid key={v4()} className="grid_cell" item xs={1.5}>
                    <Checkbox
                      onClick={() => handleClickOnChekBox(i)}
                      checked={selectedBoxes[i]}
                      disabled={!Boolean(item.id_choice)}
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: orange[600],
                        },
                      }}
                    />
                  </Grid>
                  <Grid key={v4()} className="grid_cell" item xs={3}>
                    <CustomNextBtn
                      className="go-to-question"
                      onClick={() => handleSolveThisQuestio(item, item.status)}
                    >
                      {item.status !== "validated" ? (
                        <span
                          dangerouslySetInnerHTML={{ __html: goToQuestionText }}
                        ></span>
                      ) : (
                        <span
                          dangerouslySetInnerHTML={{ __html: detailsText }}
                        ></span>
                      )}
                    </CustomNextBtn>
                  </Grid>
                </div>
              )
          })}
        </Grid>
        <div className="pagination-container">
          <div className="pagenation">
            <Button onClick={handlePreviousPage} disabled={page <= 1}>
              <ChevronLeftIcon
                style={{ color: page <= 1 ? "#863d00" : "#ed6c02" }}
              />
            </Button>
            <div className="content">
              <span className="page-num"> {page}</span>
              <span style={{ fontSize: "20px" }}>/</span>
              <span className="total-page-num">{maxPage}</span>
            </div>
            <Button onClick={handleNextPage} disabled={page >= maxPage}>
              <ChevronRightIcon
                style={{ color: page >= maxPage ? "#863d00" : "#ed6c02" }}
              />
            </Button>
          </div>
        </div>
        <div className="btns-container">
          <CustomNextBtn onClick={handleAbandon}>
            <span>Abandon</span>
          </CustomNextBtn>
          {pdfLoading ? (
            <CircularProgress
              size={40}
              thickness={4}
              style={{ color: "#ff6500", margin: "auto" }}
            />
          ) : (
            <div className="pdf-btn" onClick={handleTheFile}>
              <img src={pdfFile} alt="" />
              <span dangerouslySetInnerHTML={{ __html: pdfFileText }}></span>
            </div>
          )}

          <CustomNextBtn onClick={handleValider}>Valider</CustomNextBtn>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ roadMapId, userId, common }) => ({
  roadMapId,
  userId,
  common,
})

export default connect(mapStateToProps)(ChoosenImprovment)

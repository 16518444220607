import React, { useEffect, useState } from "react"
import "./styles.scss"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import QuestionInfo from "./QuestionInfo"
import { useHistory, useRouteMatch } from "react-router"
import { get_questins_list, get_question } from "../../../services/questions"
import CircularProgress from "@material-ui/core/CircularProgress"
import { connect } from "react-redux"
import { get_status_sv } from "../../../services/superVision"
import Alert from "../../utilities/Modals/alert/Alert"
import { set_log_time } from "../../../redux/actions/timeAction"
import { v4 } from "uuid"
import { get_second_connection } from "../../../services/secondconnection"
import { set_sv_id } from "../../../redux/actions/superVision"

const QuestionsList = ({
  userId,
  dispatch,
  svId,
  tr_,
  type_of_connection = "second",
  checkTheTimeGab,
  handleLogOut,
}) => {
  let { path, url } = useRouteMatch()
  const go = useHistory()

  useEffect(() => {
    if (!tr_.tr_1) {
      go.push("/home/info/2")
    }
    handleTheautologout()
  }, [])

  function handleTheautologout() {
    if (checkTheTimeGab()) {
      handleLogOut()
    } else {
      dispatch(set_log_time(new Date().getTime()))
    }
  }

  const [title, setTitle] = useState("")
  const [qss, setqss] = useState([])
  const [curCatigory, setcurCatigory] = useState(0)
  const [categoryQuestion, setcategoryQuestion] = useState([])
  const [loading, setLoading] = useState(false)
  const [curCatigoryQnumber, setcurCatigoryQnumber] = useState(0)
  const [alertStatus, setalertStatus] = useState(false)
  const [idxOfCategory, setidxOfCategory] = useState(0)

  useEffect(() => {
    setLoading(true)
    get_questins_list(svId.id)
      .then(async (res) => {
        const respo = await res.json()
        return respo
      })
      .then((respo) => {
        setqss(
          [...respo.data].map((item) => ({
            ...item,
            className: "question",
            text: item.name,
          }))
        )
        setLoading(false)
      })
      .catch((e) => {
        console.log(e.message)
        setLoading(false)
      })
  }, [])

  async function getWhereToData() {
    const res = await get_second_connection(userId.user_id)
    const resJson = await res.json()
    dispatch(set_sv_id(resJson.supervision.id))
    return resJson
  }

  function checkIFNull(value) {
    return (
      value === null ||
      value === "null" ||
      value === "" ||
      value === "new super Vision"
    )
  }

  useEffect(() => {
    getTheStatus().then(async (data) => {
      if (data.status === "finished") {
        const dataWhereTo = await getWhereToData()
        if (checkIFNull(dataWhereTo.supervision.name)) {
          go.push({
            pathname: `${url}/finish`,
          })
        } else {
          go.push({
            pathname: `${url}/chart`,
          })
        }
      }
    })
  }, [])

  const setTheCategoryQuestions = async (id) => {
    const response = await get_question(id)
    const res = await response.json()
    setcategoryQuestion([...res.data])
    setcurCatigoryQnumber(res.data.length)
  }

  async function getTheStatus() {
    const response = await get_status_sv(svId.id)
    const res = await response.json()
    return res
  }

  function handleQuestion(id = 0, idx = 0) {
    setidxOfCategory(idx)
    setqss((cur) =>
      cur.map((item) => {
        if (item.id === id) {
          setTitle(item.text)
          setcurCatigory(id)
          setTheCategoryQuestions(id)
          return { ...item, className: "cur-question" }
        } else return { ...item, className: "question" }
      })
    )
  }

  function handleCloseAlert() {
    setalertStatus(false)
  }
  return (
    <div className="questions-info-container">
      <Alert
        alertStatus={alertStatus}
        title="veuillez sélectionner une catégorie"
        handleCloseAlert={handleCloseAlert}
      />
      <div className="questions-list">
        <div className="line"></div>
        <ul className="questions-names-container">
          {loading ? (
            <CircularProgress
              size={50}
              thickness={4}
              style={{ color: "#ff6500", marginRight: "3px" }}
            />
          ) : (
            qss.map((item, i) => (
              <li
                onClick={() => {
                  handleQuestion(item.id, i)
                }}
                key={v4()}
                className={`${item.className} 
                ${
                  item.questions_answered === item.questions_total &&
                  item.className !== "cur-question"
                    ? "done"
                    : ""
                }`}
              >
                {item.className === "cur-question" && (
                  <ChevronRightIcon className="question-icon" />
                )}
                {item.questions_answered}/{item.questions_total} - {item.text}
              </li>
            ))
          )}
        </ul>
      </div>
      <QuestionInfo
        dataOfCategory={qss[idxOfCategory]}
        goToQuestions={() => {
          if (curCatigory === 0) {
            setalertStatus(true)
          } else {
            go.push({
              pathname: `${url}/questions`,
              state: {
                category: curCatigory,
                categorys_Question: categoryQuestion,
                lst_q_idx: 0,
              },
            })
          }
        }}
        title={title}
        numberQuestions={curCatigoryQnumber}
      />
    </div>
  )
}
const mapStateToProps = ({ userId, svId, tr_ }) => ({
  userId,
  svId,
  tr_,
})
export default connect(mapStateToProps)(QuestionsList)

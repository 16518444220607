import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import join from "classnames"

import { useHistory } from "react-router"

import { CUSTOM_TABLE_STYLE } from "../../constants/tableStyle"

import { fetchRetrieveCompanyStatistics } from "../../services/reports"
import { set_supervision_id } from "../../redux/actions/reports"

import { CircularProgress } from "@material-ui/core"
import DataTable from "react-data-table-component"

import CustomNextBtn from "../utilities/Buttons/NextButton"

import "./styles.scss"

const Reports = ({ userId, dispatch }) => {
  const [loading, setLoading] = useState(false)
  const [reports, setReports] = useState([])

  const history = useHistory()

  useEffect(() => {
    setLoading(true)
    fetchRetrieveCompanyStatistics(userId.user_id)
      .then((data) => {
        return data.companies
      })
      .then((res) => {
        setReports(res)
      })
    setLoading(false)
  }, [userId.user_id])

  const handleClick = async (supervisionId) => {
    dispatch(set_supervision_id(supervisionId))
    history.push(`/answers/${supervisionId}`)
  }

  if (loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress
          size={50}
          thickness={4}
          style={{ color: "#ff6500", marginRight: "3px" }}
        />
      </div>
    )
  }

  const columns = [
    {
      name: "Societe",
      selector: (row) => row.societe,
      maxWidth: "250px",
    },
    {
      name: "No. de Questionnaires",
      selector: (row) => row.nombre,
      center: true,
      style: {
        whiteSpace: "none",
      },
      maxWidth: "200px",
    },
    {
      name: "Feuilles de route",
      selector: (row) => row.feuilles,
      maxWidth: "250px",
      center: true,
    },
    {
      name: "Debut du Questionnaire",
      selector: (row) => row.debut,
      maxWidth: "300px",
      center: true,
    },
    {
      name: "Avancement",
      selector: (row) => row.avancement,
      maxWidth: "200px",
      center: true,
    },
    {
      name: "Niveau de risque",
      selector: (row) => row.niveau,
      sortable: true,
      maxWidth: "250px",
      center: true,
    },
    {
      name: "",
      selector: (row) => row.details,
      width: "230px",
    },
  ]

  const data = reports.map((report, index) => {
    const answeredQuestions = report.supervision.answered_questions

    return {
      id: index,
      societe: report.name,
      nombre: report.no_of_supervisions,
      debut: report.supervision.datetime,
      avancement: `${answeredQuestions} / ${report.supervision.total_questions}`,
      niveau: report.supervision.risk,
      feuilles: `${report.supervision.roadmaps.finished} / ${report.supervision.roadmaps.total} (${report.supervision.roadmaps.percentage}%)`,
      details: (
        <CustomNextBtn
          className={join("openReport", !answeredQuestions && "disabledButton")}
          disabled={!answeredQuestions}
          onClick={() => {
            handleClick(report.supervision.id)
          }}
        >
          Détails
        </CustomNextBtn>
      ),
    }
  })

  return (
    <>
      <div className="improvement-container">
        <DataTable
          className={"customTableStyle"}
          columns={columns}
          data={data}
          customStyles={CUSTOM_TABLE_STYLE}
          responsive
          noDataComponent={
            <div style={{ textAlign: "center", background: "black" }}>
              <CircularProgress
                size={50}
                thickness={4}
                style={{
                  color: "#ff6500",
                  marginRight: "3px",
                }}
              />
            </div>
          }
        />
      </div>
      <div className="audit-copyright">
        <p>Audit de cybersécurité - © Les Oies du Cyber</p>
      </div>
    </>
  )
}

const mapStateToProps = ({ userId, firstReport }) => ({
  userId,
  firstReport,
})

export default connect(mapStateToProps)(Reports)
